import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { clamp } from 'ramda';

type Props = PropsWithChildren<{
  text?: string;
  open: boolean;
  maxWidth?: number;
}>;

const useScreenWidth = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const listener = debounce(() => setWidth(window.innerWidth), 50);
    window.addEventListener('resize', listener)
    listener()
    return () => window.removeEventListener('resize', listener)
  }, []);
  return width
}

const HelpTooltip: React.FC<Props> = ({open, text, maxWidth, children}) => {
  const contents = children ?? text;
  const ref = useRef<HTMLDivElement>(null);
  const [xTransform, setXTransform] = useState(0);
  const screenWidth = useScreenWidth()

  // when the screen gets narrow, this is used to stop the tooltip going off the screen
  useEffect(() => {
    if (ref.current && screenWidth > 200) {
      const { width, x } = ref.current.getBoundingClientRect();
      const padding = 15;
      if (x<0) {
        const overhang = -x;
        setXTransform(clamp(0, (width/2 - padding), overhang * 2 + padding));
      } else if (x + width > screenWidth) {
        const overhang = (x + width) - screenWidth;
        setXTransform(clamp(-(width/2 - padding), 0, -overhang * 2 + padding));
      }
    }
  }, [ref.current, screenWidth])
  if (!contents) return null;

  const openStyles = open ? styles.open : null;

  return (
    <div role="tooltip" ref={ref} style={{ maxWidth, transform: `translateX(-50%) translateX(${xTransform}px)` }} className={classNames(styles.tooltip, openStyles)}>
      {contents}
      <div style={{ transform: `translate(-50%, -50%) translateX(${-xTransform}px) rotateZ(-45deg)`}} className={styles.tooltipArrow} />
    </div>
  )
}

export default HelpTooltip;