import React, { useContext, useEffect, useState } from 'react';

import { Footer, Header } from '../sections';

import DrawerRoot from '../../context/DrawerRoot/DrawerRoot';
import { WebviewEventContext } from '../../context/WebviewEventContext';
import PushNotificationsPopup from '../core/PushNotificationsPopup';

import { ConfigStoryblok } from '../../component-types-sb';
import { ISbStoryData } from 'storyblok-js-client';
import { useRouter } from 'next/router';
import MobileSplashScreen from '../sections/MobileSplashScreen';
import { hiddenHeaderURLs } from '../../utils/constants';
import ModalRoot from '../../context/ModalRoot';
import Banner from '../banner';

interface Props {
  children: React.ReactNode;
  config?: ISbStoryData<ConfigStoryblok>;
}

//TODO: there's probably a better spot for trustpilot widget
export const Layout = ({ children, config, ...props }: Props) => {
  const [showDelayedNotificationPopup, setShowDelayedNotificationPopup] = useState<boolean>(false);
  const [willOpenPopup, setWillOpenPopup] = useState<boolean>(false);
  const { notificationStatus, isMobileApp, popupComplete, showPopup, showSplashScreen } =
    useContext(WebviewEventContext);
  const router = useRouter();

  const showHeader = hiddenHeaderURLs.filter((url) => router.asPath.includes(url)).length === 0;
  const isProductPage = router.asPath.includes('/products/');
  const shouldShowNotificationsPopup = !notificationStatus && isMobileApp && !popupComplete && showPopup;

  useEffect(() => {
    if (shouldShowNotificationsPopup) {
      setWillOpenPopup(true);
      setTimeout(() => {
        setShowDelayedNotificationPopup(true);
        document.body.classList.add('popup-open');
        setWillOpenPopup(false);
      }, 1000);
    } else if (showDelayedNotificationPopup && !willOpenPopup) {
      setShowDelayedNotificationPopup(false);
      document.body.classList.remove('popup-open');
    }
  }, [shouldShowNotificationsPopup, notificationStatus]);

  const splash = isMobileApp && showSplashScreen;

  //sets overflow on body to hidden whilst splash is visible
  useEffect(() => {
    if (splash) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showSplashScreen]);

  return (
    <div style={{ overflow: showSplashScreen ? 'hidden ' : 'auto' }}>
      <script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      ></script>
      {showDelayedNotificationPopup && <PushNotificationsPopup />}
      {showHeader && <Header config={config} />}
      {splash && <MobileSplashScreen />}
      
      {isMobileApp && <Banner content={"This app is no longer supported. Please visit our website instead : thrift.plus."}/>}
      {router.asPath.includes('/thrift-bags/register') &&
        <Banner className="p-2 bg-blackAlt text-white" content={"Due to high demand, bags are currently taking 2 weeks to process."}/>
      }
      <div className="min-h-screen">{children}</div>
      <Footer config={config} />
      <DrawerRoot />
      <ModalRoot />
    </div>
  );
};
