import { useEffect, useState } from 'react';
import { CanonicalItem } from '../../../../../data-fetching/canonicalItem';
import { Icon, LoadingAnimation } from '../../../../core';
import styles from './styles.module.css';
import { useCustomer } from '../../../../account/Payout/useCustomer';
import { LoginOrRegister } from '../../../../account/Authentication/LoginOrRegister';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  item: CanonicalItem;
}

export const OfferModal: React.FC<Props> = ({ isOpen, onClose, item }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { customer } = useCustomer();

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const jotFormUrl = `https://form.jotform.com/250272144875054?email=${customer?.email}&sku=${item.sku}&offer=${item?.price.toFixed(2)}`;

  return (
    <div className={styles.overlay} onClick={(e) => { e.target === e.currentTarget ? onClose() : undefined}}>
      <div className={styles.content}>
        <button onClick={onClose} className={styles.closeButton}>
          <Icon title={'close'} color="transparent" />
        </button>
        <LoginOrRegister>
            {isLoading && <div className="mt-40 flex absolute w-full"><LoadingAnimation className="m-auto" /></div>}
            <iframe
              src={jotFormUrl}
              className={styles.iframe}
              title="Make an Offer"
              onLoad={(() => {
                setIsLoading(false)
              })}
            ></iframe>
        </LoginOrRegister>
      </div>
    </div>
  );
};