import React, { useState, useEffect, useContext, cache } from 'react';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { Icon, Image, RichText } from '../../../core';

import styles from './styles.module.css';
import PageHeroStoryblok from './types';
import SignpostLink from '../../../core/SignpostLink/index';
import classNames from 'classnames';
import { bgColorTextToValue } from '../../../../utils/constants';
import Modal from 'react-modal';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { CheckoutCartContext } from '../../../../context/CheckoutCartContext';
import { Signpost } from '../../../core';
import { useThriftBagCartLine } from '../../../../data-fetching/cart/useCartLine';

interface Props {
  blok: PageHeroStoryblok;
}

Modal.setAppElement('body');

declare global {
  interface Window {
    _klOnsite: any[];
  }
}


const PageHero = ({ blok }: Props) => {
  const {
    title,
    title_large,
    subtitle,
    text_align,
    button_title,
    button_url,
    button_action,
    image,
    image_large,
    image_large_position,
    background_color,
    modal,
  } = blok || {};
  const router = useRouter();
  const [isLoaded, setIsLoaded] = useState(false);
  const isLarge = useMediaQuery({
    query: '(min-width: 48rem)',
  });

  const { openCart } = useContext(CheckoutCartContext);
  const { addToCart: addThriftBagToCart } = useThriftBagCartLine();

  const shouldAddToBag = button_action === 'bag';
  const shouldSendToWaitlist = button_action === 'waitlist';

  const cachedURL = !shouldAddToBag && !shouldSendToWaitlist ? button_url?.cached_url : null;
  const imgSrc = image?.filename;
  const imgLargeSrc = image_large?.filename;
  const imgIsBackground = image_large_position === 'background';
  const src = isLoaded && isLarge && imgLargeSrc ? imgLargeSrc : imgSrc;

  const isOpen = router?.query?.started === 'true';

  const modalBlok = modal?.[0];

  const customStyles = {
    content: {
      inset: '50% 0 0 50%',
      padding: 0,
      height: isLarge ? '90vh' : '100vh',
      width: '100%',
      maxWidth: 600,
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      borderRadius: 0,
      border: 'none',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
  };

  const hideModal = () => router.replace('/clearout', undefined, { shallow: true });

  const handleAddToCart = async () => {
    if (addThriftBagToCart) {
      await addThriftBagToCart();
    } else {
      openCart();
    };
  };

  const handleWaitlistClick = () => {
    if (shouldSendToWaitlist) {
      if (typeof window !== 'undefined') {
        window._klOnsite = window._klOnsite || []; 
        window._klOnsite.push(['openForm', 'TfSzrp']);
      }
    }
  };

  useEffect(() => {
    // Hacky way to prevent ssr error
    setIsLoaded(true);
  }, []);

  return (
    <section>
      <div
        className={classNames(styles.hero, { [styles.hasImage]: src, [styles.enlargeTitle]: title_large === true, [styles.textLeft]: text_align === 'left',
        [styles.textRight]: text_align === 'right',
        [styles.textCentre]: text_align === 'centre', [styles.hasBgImage]: imgIsBackground })}
        style={{ backgroundColor: bgColorTextToValue[background_color] }}
        {...storyblokEditable(blok)}
      >
        <div className={styles.content}>
          <RichText className={classNames(styles.title, styles.enlarge, 'display-24-caps')} document={title} />
          {subtitle && <RichText className="body-16 mb-4" document={subtitle} />}
          <div className="inline-block">
            {button_title && (
              <div className={styles.signpost} >
                <SignpostLink signpostText={button_title} iconRight={''} href={cachedURL} onClick={shouldSendToWaitlist ? handleWaitlistClick : handleAddToCart}/>
              </div>
            )}
            <div className="mt-2">
              <Signpost signpostText='Return a bag' href={"/account/thrift-bags/register"}></Signpost>
            </div>
          </div>
        </div>
        {src && <Image src={src} alt="" className={styles.image} />}
      </div>
      {modal && (
        <Modal isOpen={isOpen} onRequestClose={hideModal} style={customStyles}>
          <div className="bg-brandYellow25 h-full w-full">
            <button type="button" className={styles.closeButton} onClick={hideModal}>
              <Icon title={'close'} color="transparent" />
              <span className="sr-only">Close</span>
            </button>

            <StoryblokComponent
              blok={modalBlok}
              desktopSlidesPerView={1}
              offsetDotsDesktop={false}
              className={styles.modalCarousel}
            />
          </div>
        </Modal>
      )}
    </section>
  );
};

export default PageHero;
