import { useReducer } from 'react';
import { mapObjectValues } from '../../utils/objectHelpers';

type BaseForm = Record<string | number | symbol, string | number | null | undefined | boolean>;

function reduceFormState<FormState extends BaseForm, K extends keyof FormState>(state: FormState, payload: { field: K, value: FormState[K]} ): FormState {
  return {
    ...state,
    [payload.field]: payload.value,
  }
}



export type SimpleFormManager<Form extends BaseForm> = {
  [K in keyof Form]: {
    state: Form[K],
    update: ((update: Form[K]) => void),
    isValid: boolean,
    validate: FieldOnlyValidator
  }
}
type FieldOnlyValidator = (value: any) => boolean;
type SimpleFormValidator<Form extends BaseForm> = (value: any, fullForm: Form) => boolean;
export type SimpleFormValidators<Form extends BaseForm> = Partial<{ [K in keyof Form]: RegExp | SimpleFormValidator<Form> | undefined }>

const toValidator = <Form extends BaseForm>(validationMethod: RegExp | SimpleFormValidator<Form> | undefined): SimpleFormValidator<Form>  => {
  if (typeof validationMethod === 'undefined') return () => true;
  if (validationMethod instanceof RegExp) return (text) =>{ return validationMethod.test(text)};
  return validationMethod;
}


export const useSimpleForm = <Form extends BaseForm>(defaultState: Form, validators: SimpleFormValidators<Form> = {}) => {
  const [state, dispatch] = useReducer(reduceFormState, defaultState);

  const formManager = mapObjectValues<Form, SimpleFormManager<Form>>(state as Form, (key, value) => {
    const validator = toValidator(validators[key]);
    return {
        state: value,
        isValid: validator(value, state as Form),
        validate: (testValue:any) => validator(testValue, state as Form),
        update: (nextValue: typeof value) => {
          dispatch({ field: key, value: nextValue })
        }
      };
  })

  const isValid = (Object.values(formManager)).map(fieldManager => fieldManager.isValid).reduce((restOfFormIsValid, inputIsValid) => restOfFormIsValid && inputIsValid, true);
  return {
    formData: (state as Form),
    formManager,
    isValid,
  };
}