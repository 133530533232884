import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

type AsyncToggleProps = {
  checked: boolean;
  // onToggle: (newState: boolean) => (Promise<unknown>) | unknown;
  onToggle: (newState: boolean) => Promise<unknown> | void;
  a11yLabel: string;
  forceLoading?: boolean;
  disabled?: boolean;
}

export const  AsyncToggle: React.FC<AsyncToggleProps> = ({ checked, forceLoading, onToggle, disabled, a11yLabel}) => {

  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    if (isLoading || disabled) return;

    setIsLoading(true);

    try {
      const toggleResult = onToggle(!checked);

      if (toggleResult instanceof Promise) {
        await toggleResult;
      }
    } catch (e) {
      // we actually don't care if this errors, just that it's done
    }

    setIsLoading(false);
  };

  const pipRadius = 10;
  const pipGap = 3;
  const toggleWidth = 54;
  const pipDiameter = pipRadius * 2;
  
  const loadingStyles = (isLoading || forceLoading) ? 
    { 
      left: (toggleWidth - pipDiameter) / 2,
      spinnerOpacity: 1,
      backgroundColor: "#879C96",
      cursor: 'wait'
    }: {
      spinnerOpacity: 0,
      cursor: 'pointer'
    };
  const disabledStyles = {
    cursor: disabled ? 'not-allowed' : loadingStyles.cursor,
    opacity: disabled ? 0.5 : 1,
  };

  const conditionalStyles = checked ? 
    {
      backgroundColor: loadingStyles.backgroundColor ?? "#86C3B1",
      pipColor: "#FFFFFF",
      pipLeft: loadingStyles.left ?? (toggleWidth - pipDiameter - pipGap),
    }:
    {
      backgroundColor: loadingStyles.backgroundColor ?? "#888888",
      pipColor: "#FFFFFF",
      pipLeft: loadingStyles.left ?? pipGap,
    };




  return (
    <div 
      onClick={() => onClick()} 
      onKeyPress={(e) => {
        console.log(e)
        if (e.charCode === 13) {
          // charcode for the enter key
          e.preventDefault();
          onClick();
        }
      }}
      
      style={{ 
        height: (pipRadius + pipGap) * 2, 
        width: toggleWidth, 
        borderRadius: 5000, 
        background: conditionalStyles.backgroundColor, 
        display: "block", 
        position: "relative",
        transition: "0.3s ease",
        flexShrink: 0,
        cursor: disabledStyles.cursor,
        opacity: disabledStyles.opacity,
      }}>
        <div 
          tabIndex={disabled ? -1 : 0}
          aria-checked={checked} 
          aria-label={a11yLabel}
          role='checkbox'
          style={{ 
            height: pipDiameter, 
            width: pipDiameter, 
            borderRadius: 5000, 
            background: conditionalStyles.pipColor, 
            position: "absolute",
            top: pipGap, 
            left: conditionalStyles.pipLeft, 
            display: "flex",
            transition: "0.3s ease",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={classNames(styles.toggleSpinner)} style={{ opacity: loadingStyles.spinnerOpacity}}></div>
        </div>
    </div>
  );
}

export const LoadingSpinner = () => {
  return <div className={classNames(styles.toggleSpinner)}></div>
}