import { PropsWithChildren, useState } from 'react';
import { InfoCircle } from '../../core/Icons/iconList';
import HelpTooltip from '../../core/HelpTooltip';
import classNames from 'classnames';

type Props = {
  maxWidth?: number;
  className?: string;
}

export const Help: React.FC<PropsWithChildren<Props>> = ({ children, maxWidth, className }) => {
  const [openToolTip, setOpenToolTip] = useState(false);
  
  if (!children) return null;
  return (
    <span className={classNames('relative inline-block', className)}
          onMouseEnter={() => setOpenToolTip(true)}
          onMouseLeave={() => setOpenToolTip(false)}
          tabIndex={0}
          onFocus={() => setOpenToolTip(true)}
          onBlur={() => setOpenToolTip(false)}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setOpenToolTip(false)
            }
          }}
    >
      <InfoCircle width={15} height={15} />
      <HelpTooltip maxWidth={maxWidth} open={openToolTip}>{children}</HelpTooltip>
    </span>
  );
};